import Students from '../../../pages/MasterData/General/Students';
import StudentsForm from '../../../pages/MasterData/General/Students/Form/StudentsForm';

export const students = {
    path: 'students',
    children: [
        {
            path: '',
            element: <Students />,
            name: 'md_general_students_list',
        },
        {
            path: 'base',
            children: [
                {
                    path: 'show/:id',
                    element: <StudentsForm view="base" readOnly />,
                    name: 'md_general_students_list_base_show',
                },
            ],
        },
        {
            path: 'read-only/show/:id',
            element: <StudentsForm view="detailed" readOnly />,
            name: 'md_general_students_list_read_only_show',
        },
        {
            path: 'base-read-only/show/:id',
            element: <StudentsForm view="base" readOnly />,
            name: 'md_general_students_list_base_read_only_show',
        },
        {
            path: ':id/edit/base',
            element: <StudentsForm view="base" />,
            name: 'md_general_students_edit_base',
        },
        {
            path: ':id/edit/detailed',
            element: <StudentsForm view="detailed" />,
            name: 'md_general_students_edit_detailed',
        },
        {
            path: 'new',
            element: <StudentsForm view="base" />,
            name: 'md_general_students_create',
        },
    ],
};
