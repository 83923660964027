import MailIcon from '@mui/icons-material/Mail';
import Visibility from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Link, TableButton, TableEditButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EndpointProvider from '../../../../components/EndpointProvider';
import usersService from '../../../../services/masterData/users';
import { create } from '../../../../store/notification';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import TableButtonArchive from '../../../../components/Buttons/TableButtonArchive';
import { RootState } from '../../../../store';

export function ActivationEmailButton({
    id,
    isDisabled,
    activationEmailSent,
    setRefresh,
}: {
    id: number;
    isDisabled: boolean;
    activationEmailSent: boolean;
    setRefresh: () => void;
}) {
    const dispatch = useDispatch();

    const [open, setOpen] = useState<boolean>(false);

    const handleSendActivationEmail = (userId: number) => {
        if (!isDisabled) {
            usersService
                .sendActivationEmail(userId, process.env.REACT_APP_TENANT_ID || '1')
                .then(() => {
                    dispatch(create({ type: 'success', message: 'Az aktivációs e-mail sikeresen elküldve.' }));
                    setRefresh();
                })
                .catch(() =>
                    dispatch(
                        create({ type: 'error', message: 'Az aktivációs e-mail kiküldése közben hiba lépett fel!' }),
                    ),
                )
                .finally(() => {
                    setOpen(false);
                });
        }
    };

    return (
        <>
            <TableButton
                color="info"
                onClick={() => setOpen(true)}
                tooltip={activationEmailSent ? 'Aktivációs e-mail újraküldése' : 'Aktiváló e-mail küldése'}
            >
                <MailIcon />
            </TableButton>
            <Dialog onBackdropClick={() => setOpen(false)} open={open} onClose={() => setOpen(false)} maxWidth={'lg'}>
                <DialogTitle>
                    {activationEmailSent ? 'Aktivációs e-mail újraküldése' : 'Aktiváló e-mail küldése'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {!isDisabled ? (
                            <Typography variant="h5" mt={3} mb={3} gutterBottom component="div">
                                {activationEmailSent
                                    ? 'Biztosan újra elküldi az aktivációs üzenetet a felhasználó részére?'
                                    : 'Biztosan elküldi az aktivációs üzenetet a felhasználó részére?'}
                            </Typography>
                        ) : (
                            <Typography variant="h5" mt={3} mb={3} gutterBottom component="div">
                                Hiba! Letiltott felhasználónak nem küldhető e-mail. Az e-mail küldést megelőzően oldja
                                fel a felhasználó letiltását!
                            </Typography>
                        )}
                    </DialogContentText>
                    <Grid container spacing={2} flexDirection={'row-reverse'}>
                        {!isDisabled ? (
                            <>
                                <Grid item>
                                    <Button variant="contained" onClick={() => setOpen(false)}>
                                        Nem
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={() => handleSendActivationEmail(id)}>
                                        Igen
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <Grid item>
                                <Button variant="contained" onClick={() => setOpen(false)}>
                                    Vissza
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export function PasswordReminderButton({
    id,
    setRefresh,
    isDisabled,
    activated,
}: {
    id: number;
    setRefresh: () => void;
    isDisabled: boolean;
    activated: boolean;
}) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState<boolean>(false);

    const handleSendForgotPasswordTokenEmail = (userId: number) => {
        if (!isDisabled) {
            usersService
                .sendForgotPasswordTokenEmail(userId)
                .then(() => {
                    dispatch(create({ type: 'success', message: 'Az elfelejtett jelszó e-mail sikeresen kiküldve' }));
                    setRefresh();
                })
                .catch(() =>
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Az elfelejtett jelszó e-mail kiküldése közben hiba lépett fel',
                        }),
                    ),
                )
                .finally(() => {
                    setOpen(false);
                });
        }
    };

    return activated ? (
        <>
            <Grid item>
                <TableButton color="warning" onClick={() => setOpen(true)} tooltip={'Jelszóemlékeztető e-mail küldése'}>
                    <MailIcon />
                </TableButton>
            </Grid>

            <Dialog onBackdropClick={() => setOpen(false)} open={open} onClose={() => setOpen(false)} maxWidth={'lg'}>
                <DialogTitle>Jelszóemlékeztető e-mail elküldése</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {!isDisabled ? (
                            <Typography variant="h5" mt={3} mb={3} gutterBottom component="div">
                                Biztosan jelszóemlékeztető üzenetet akar elküldeni a felhasználó részére?
                            </Typography>
                        ) : (
                            <Typography variant="h5" mt={3} mb={3} gutterBottom component="div">
                                Hiba! Letiltott felhasználónak nem küldhető e-mail. Az e-mail küldést megelőzően oldja
                                fel a felhasználó letiltását!
                            </Typography>
                        )}
                    </DialogContentText>
                    <Grid container spacing={2} flexDirection={'row-reverse'}>
                        {!isDisabled ? (
                            <>
                                <Grid item>
                                    <Button variant="contained" onClick={() => setOpen(false)}>
                                        Nem
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={() => handleSendForgotPasswordTokenEmail(id)}>
                                        Igen
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <Grid item>
                                <Button variant="contained" onClick={() => setOpen(false)}>
                                    Vissza
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    ) : null;
}

const Operations = (params: GridRenderCellParams, readOnly: boolean, setRefresh: () => void) => {
    const { user } = useSelector((state: RootState) => state.auth);
    return readOnly || !!user.archive ? (
        <Grid container spacing={1}>
            <EndpointProvider endpoints={['md_general_students_list_base_read_only_show']}>
                <Grid item>
                    <Link
                        to={
                            clientEndPoints.md_general_students_list_base_read_only_show.replace(
                                ':id',
                                params.id.toString(),
                            ) + window.location.search
                        }
                    >
                        <TableButton tooltip={'Alapadatok megtekintése'}>
                            <Visibility />
                        </TableButton>
                    </Link>
                </Grid>
            </EndpointProvider>
            {params.row.viewable && (
                <EndpointProvider endpoints={['md_general_students_list_read_only_show']}>
                    <Grid item>
                        <Link
                            to={
                                clientEndPoints.md_general_students_list_read_only_show.replace(
                                    ':id',
                                    params.id.toString(),
                                ) + window.location.search
                            }
                        >
                            <TableButton tooltip={'Részletes adatok megtekintése'}>
                                <ViewModuleIcon />
                            </TableButton>
                        </Link>
                    </Grid>
                </EndpointProvider>
            )}
        </Grid>
    ) : (
        <Grid container spacing={1}>
            <Grid item>
                <Link
                    to={
                        clientEndPoints.md_general_students_edit_base.replace(':id', params.id.toString()) +
                        window.location.search
                    }
                >
                    <TableEditButton tooltip={'Alapadatok szerkesztése'} />
                </Link>
            </Grid>
            <Grid item>
                <Link
                    to={
                        clientEndPoints.md_general_students_edit_detailed.replace(':id', params.id.toString()) +
                        window.location.search
                    }
                >
                    <TableButton color={'secondary'} tooltip={`Részletes adatok szerkesztése`}>
                        <ViewModuleIcon />
                    </TableButton>
                </Link>
            </Grid>
            {!params.row.disabled && (
                <>
                    {!params.row.activated && (
                        <EndpointProvider endpoints={['user_send_activation_email']}>
                            <Grid item>
                                <ActivationEmailButton
                                    id={parseInt(params.row.user.id.toString())}
                                    isDisabled={params.row.disabled}
                                    activationEmailSent={params.row.activationEmailSent}
                                    setRefresh={setRefresh}
                                />
                            </Grid>
                        </EndpointProvider>
                    )}
                    <EndpointProvider endpoints={['user_send_email_forgot_password_token']}>
                        <PasswordReminderButton
                            id={parseInt(params.row.user.id.toString())}
                            setRefresh={setRefresh}
                            isDisabled={params.row.disabled}
                            activated={params.row.activated}
                        />
                    </EndpointProvider>
                </>
            )}
            {params.row.id !== user.id && !params.row.student && (
                <Grid item>
                    <TableButtonArchive
                        archive={params.row.user?.archive}
                        id={params.row.user?.id}
                        setRefresh={setRefresh}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default Operations;
