import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Accordion, CancelButton, Loading, Page } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../../../i18n';
import emailLogsService from '../../../../services/administration/emailLogs';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { IEmailLog, initialEmailLog } from './types';
import { useTenant } from '../../../../components/TenantContext';
import { formatDate } from '../../../../utils/timezoneConverter';

type RouteParams = {
    id: string;
};
export default function EmailLogShow() {
    const { id } = useParams<RouteParams>() as { id: string };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const tenant = useTenant();
    const [form, setForm] = useState<IEmailLog>(initialEmailLog);
    useEffect(() => {
        setLoading(true);
        emailLogsService
            .get(id)
            .then((res) => {
                const emailLog = res.data;
                setForm(emailLog);
            })
            .finally(() => setLoading(false));
    }, [dispatch, id]);

    const header = {
        breadcrumbs: {
            'masterData': 'Adminisztráció',
            [clientEndPoints.admin_email_logs_list]: 'E-mail napló',
            'emailLogs': 'E-mail napló megjelenítése',
        },
        title: 'E-mail napló',
        project: tenant || 'TAR',
    };
    return (
        <Page header={header}>
            <Accordion title="Email napló megjelenítés">
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ overflow: 'auto' }}>
                        {loading ? (
                            <Loading noHeight />
                        ) : !form ? (
                            'Nem található adat.'
                        ) : (
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Név</TableCell>
                                        <TableCell>
                                            {form?.user ? (
                                                <>
                                                    {form?.user?.lastName} {form?.user?.firstName} ({form?.user?.email})
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </TableCell>
                                        <TableCell>E-mail</TableCell>
                                        <TableCell>{form.mailingAddress}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Tárgy</TableCell>
                                        <TableCell>{form.subject}</TableCell>
                                        <TableCell>Státusz</TableCell>
                                        <TableCell>
                                            {i18n.t('administration.email_log.status.' + form.status) as string}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Kiküldve</TableCell>
                                        <TableCell>
                                            {form.sentAt ? formatDate(form.sentAt) : 'Nincs kiküldve'}
                                        </TableCell>
                                        <TableCell>Generálva</TableCell>
                                        <TableCell>{formatDate(form.generatedAt)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Tartalom</TableCell>
                                        <TableCell colSpan={3}>
                                            <div
                                                style={{ width: '100%', wordBreak: 'break-all' }}
                                                dangerouslySetInnerHTML={{ __html: form.emailText }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <CancelButton onClick={() => navigate(-1)}>Vissza</CancelButton>
                    </Grid>
                </Grid>
            </Accordion>
        </Page>
    );
}
