import { LoadingButton } from '@mui/lab';
import { CancelButton } from '@silinfo/front-end-template';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { Children, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import MCCLoading from '../../../../../components/MCCLoading';
import studentsService from '../../../../../services/masterData/students';
import { RootState } from '../../../../../store';
import { create } from '../../../../../store/notification';
import { bankAccountNumberFormatter, transformApiErrors } from '../../../../../utils/AppConst';
import { endpoints } from '../../../../../utils/endPoints';
import { IForm } from '../../../../../utils/Interfaces/interfaces';
import Contacts from './Contacts';
import { initialStudentProcessIds, IStudentProcessIds } from './DetailedForm';
import { DetailedForm, DetailedFormSection } from './interfaces';
import MCCStudies from './MCCStudies';
import ProfilePicture from './ProfilePicture';
import SectionGenerator from './SectionGenerator';
import StudentProcesses from './StudentProcesses';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';

const transformResponse = (form: { [key: Exclude<string, 'filePath'>]: string | { id: number; name: string } }) => {
    return Object.keys(form).reduce(
        (prev, next) => {
            if (next == 'responsibilities') {
                return {
                    ...prev,
                    [next]: form[next],
                };
            }

            if (Array.isArray(form[next])) {
                return {
                    ...prev,
                    [next]: form[next],
                };
            }

            return {
                ...prev,
                [next]:
                    typeof form[next] === 'string' || typeof form[next] === 'number'
                        ? '' + form[next]
                        : (form[next] as { id: number; name: string })?.id,
            };
        },
        {
            uuidFileName: typeof form.filePath === 'string' ? form?.filePath?.split('/')[1] : '',
            origFileName: form?.fileName,
        },
    );
};

interface PersonalDataProps {
    sections: DetailedForm[];
    section: DetailedForm;
    activeSectionState: [number, Dispatch<SetStateAction<number>>];
    readOnly?: boolean;
}
export default function PersonalData(props: PersonalDataProps) {
    const { sections, section, activeSectionState, readOnly } = props;
    const [activeSection, setActiveSection] = activeSectionState;
    const { id } = useParams();
    const [studentProcessIds, setStudentProcessIds] = useState<IStudentProcessIds>(initialStudentProcessIds);
    const fields = sections.map((section) => section.sections.map((field) => field.fields)).flat(2);
    const [initialValues, setInitialValues] = useState(
        fields.reduce((o, key) => ({ ...o, [key.name]: key.type === 'multiselect' ? [] : '' }), {}),
    );
    const backUrl = clientEndPoints.md_general_students_list + window.location.search;
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(backUrl);
    };
    const enrollmentUrls = studentProcessIds.enrollments.map((enrollmentId) =>
        endpoints.masterData.students.processes.getEnrollment.replace('{id}', enrollmentId?.toString()),
    );
    const studentAllocationPreferenceUrls = studentProcessIds.studentAllocationPreferences.map((preferenceId) =>
        endpoints.masterData.students.processes.getStudentAllocationPreference.replace(
            '{id}',
            preferenceId?.toString(),
        ),
    );
    const relocationUrls = studentProcessIds.relocations.map((relocationId) =>
        endpoints.masterData.students.processes.getRelocation.replace('{id}', relocationId?.toString()),
    );
    const exceptionUrls: string[] = useMemo(
        () => [
            endpoints.masterData.campuses.main,
            endpoints.masterData.buildings.main,
            endpoints.masterData.floors.main,
            endpoints.courseManagement.courseList.floors,
            endpoints.courseManagement.courseList.rooms,
            endpoints.masterData.trainingLevels.main,
            endpoints.masterData.trainingPrograms.main,
            endpoints.masterData.trainingPrograms.collegeYears,
            endpoints.masterData.trainingLevels.trainingLevels,
            endpoints.masterData.collegeYears.options,
            endpoints.fileManagement.downloadTemp.replaceAll('{type}', ''),
            endpoints.fileManagement.fileUpload,
            endpoints.masterData.trainingPrograms.options,
            endpoints.fileManagement.download.replace('{id}', id?.toString() || ''),
            endpoints.masterData.students.enrolledSemestersByStudent.replace('{studentId}', id?.toString() || ''),
            endpoints.masterData.students.processes.ongoing.replace('{id}', id?.toString() || ''),
            endpoints.masterData.students.processes.done.replace('{id}', id?.toString() || ''),
            endpoints.masterData.students.processes.getTermination.replace('{id}', ''),
            endpoints.masterData.students.processes.getMoveOut.replace('{id}', ''),
            endpoints.masterData.users.userSearch,
            endpoints.masterData.users.userLoginInAs,
            ...enrollmentUrls,
            ...studentAllocationPreferenceUrls,
            ...relocationUrls,
        ],
        [id, enrollmentUrls, studentAllocationPreferenceUrls, relocationUrls],
    );
    const { count, urls } = useSelector((state: RootState) => state.loading);
    const { user } = useSelector((state: RootState) => state.auth);
    const loadingUrls: boolean = useMemo(
        () => urls.every((elem: string) => !exceptionUrls.some((url) => elem.includes(url))) && urls.length > 0,
        [exceptionUrls, urls],
    );
    const [userId, setUserId] = useState('');
    const [firstLoading, setFirstLoading] = useState(true);
    const [searchParams] = useSearchParams();

    const handleSubmit = (form: Record<string, string>, { setErrors }: FormikHelpers<IForm>) => {
        const newForm = Object.entries(form).reduce((prev, [key, value]: [string, string | string[]]) => {
            return {
                ...prev,
                [key]: !Array.isArray(value) ? value || null : value?.length ? value : null,
            };
        }, {});
        studentsService
            .updateDetailed(id as string, newForm)
            .then(() =>
                dispatch(
                    create({
                        type: 'success',
                        message: 'Sikeres mentés!',
                        redirect: backUrl,
                    }),
                ),
            )
            .catch((error: AxiosError) => {
                if (error.response?.status === 422) {
                    setErrors(transformApiErrors(error.response?.data.violations));
                    dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));
                }
            });
    };

    const dispatch = useDispatch();

    useEffect(() => {
        setFirstLoading(true);
        studentsService
            .getDetailed(id as string)
            .then((response) => {
                setUserId(response.data.data.userId);
                setInitialValues((prev) => ({
                    ...prev,
                    ...transformResponse(response.data.data),
                }));
            })
            .finally(() => setFirstLoading(false));
    }, [id, searchParams]);

    if (firstLoading) return <MCCLoading />;

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validateOnBlur={false} validateOnChange={false}>
            {(formikProps) => (
                <Form>
                    <ProfilePicture userId={userId} />
                    {Children.toArray(
                        section.sections.map((field, j) => (
                            <SectionGenerator
                                key={field.name}
                                section={
                                    {
                                        ...field,
                                        formikProps,
                                        fields: field.fields.map((f) => ({
                                            ...f,
                                            fieldType: f.name === 'bankAccountNumber' ? 'base' : 'fast',
                                            props: {
                                                ...f.props,
                                                ...(readOnly || user.archive ? { disabled: true } : {}),
                                                ...(f.name === 'bankAccountNumber'
                                                    ? {
                                                          onChange: bankAccountNumberFormatter(
                                                              formikProps.setFieldValue,
                                                          ),
                                                          error: (formikProps.errors as { bankAccountNumber: string })
                                                              .bankAccountNumber,
                                                          helperText: (
                                                              formikProps.errors as { bankAccountNumber: string }
                                                          ).bankAccountNumber,
                                                      }
                                                    : {}),
                                            },
                                        })),
                                    } as DetailedFormSection
                                }
                                loading={count > 0 && loadingUrls}
                                activeSection={activeSection}
                                setActiveSection={setActiveSection}
                                readOnly={readOnly}
                                number={j}
                            />
                        )),
                    )}
                    <Contacts
                        loading={loadingUrls}
                        activeSection={activeSection}
                        setActiveSection={setActiveSection}
                        number={4}
                        formikProps={formikProps}
                        readOnly={!!readOnly || user.archive}
                    />
                    <MCCStudies
                        loading={loadingUrls}
                        activeSection={activeSection}
                        setActiveSection={setActiveSection}
                        number={5}
                        formikProps={formikProps}
                        readOnly={!!readOnly || user.archive}
                    />
                    <StudentProcesses
                        loading={loadingUrls}
                        activeSection={activeSection}
                        setActiveSection={setActiveSection}
                        number={6}
                        studentProcessIds={studentProcessIds}
                        setStudentProcessIds={setStudentProcessIds}
                    />
                    <div style={{ position: 'relative', top: '10px' }}>
                        {!readOnly && !user.archive && (
                            <LoadingButton
                                variant="contained"
                                loading={count > 0}
                                type="submit"
                                style={{ marginRight: '4px' }}
                            >
                                Mentés
                            </LoadingButton>
                        )}

                        <CancelButton onClick={handleBack}>Mégsem</CancelButton>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
